/* globals Cell, ClassDecorator, Configuration, DataCell, DataPackage, Display, Enumeration, Enums, Filename, Grid, HeaderCell, Name */
(function () {
  'use strict';
  const DECIMAL_POINT = '.';
  const INPUT_DECIMAL_REGEX_PATTERN = '[-]?[0-9]*([.][0-9]+)?';
  const EMPTY_STRING = '';
  const CHART_DATASET = {
    COLOR: Enumeration
      .create('primary', 'comparison first', 'comparison second', 'actualsPYoY', 'actualsPPYoY')
      .asExactValue('#2ca02c', '#77BDFF', '#1717FC', '#C389FA', '#8200FF'),
    KEY: Enumeration
      .create('primary', 'comparison first', 'comparison second', 'actualsPYoY', 'actualsPPYoY')
      .asExactValue('Primary', 'Comparison 1', 'Comparison 2', 'PYoY', 'PPYoY')
  };
  // TODO: This logic is specific to the Plan Editor and should be provided by Plan Editor to the Plan Grid.
  const FC_WEEKLY_PLAN_PREFINAL_INDEX = 0;
  const FC_WEEKLY_PLAN_PREVIEW_INDEX = 1;
  const FC_WEEKLY_PLAN_DIFFERENCE_INDEX = 2;

  class PlanGridController {
    static get $inject () {
      return ['trader', 'transformerFactory'];
    }

    constructor (trader, transformerFactory) {
      this.gridData = {};
      this.masterset = {};
      this.rows = {
        body: [],
        footer: []
      };
      this.actions = {
        download: (format) => transformerFactory.toDocument(transformerFactory.transformerType.plan,
          format,
          [this.masterset],
          [
            {
              key: 'granularity',
              serialize: (granularity) => granularity.grains.names()
            },
            'Data Type',
            { key: 'dates' }
          ],
          [
            {
              key: 'granularity',
              serialize: (granularity, source, row) => granularity.grains.values().map((grain) => row.granularity[grain.id]),
              source: 'pkg'
            },
            {
              key: 'dataType',
              source: 'row'
            },
            {
              key: 'values',
              source: 'row'
            }
          ]
        ).then((workbook) => {
          trader.download(
            workbook,
            Filename.create().forPlan(this.masterset.plan).add(this.masterset.title),
            trader.toExtensionType(format)
          );
        })
      };
    }

    // TODO: This logic is specific to the Plan Editor and should be provided by Plan Editor to the Plan Grid.
    // In Plan Editor the rows are organized as:
    //   1. Baseline (optional)
    //   2, Prefinal
    //   3. Final (optional)
    //   4. Edit
    //   5. Preview (Delta editing only)
    _getRelatedPrefinalRowIndex (dataset, currentRowIndex) {
      // When a Final row is absent, Prefinal row is right before Edits
      if (dataset[currentRowIndex - 1].dataType === 'Prefinal') {
        return currentRowIndex - 1;
      }
      // When a Final row is present, Final row lies between Prefinal and Edits
      return currentRowIndex - 2;
    }

    // TODO: This logic is specific to the Plan Editor and should be provided by Plan Editor to the Plan Grid.
    _getRelatedPreviewRowIndex (currentRowIndex) {
      return this.masterset.plan.isFcWeeklyPlan() ? FC_WEEKLY_PLAN_PREVIEW_INDEX : currentRowIndex + 1;
    }

    _getRelatedPreviewCell (plan, rowIndex, colIndex) {
      const dataset = plan.isFcWeeklyPlan() ? this.rows.footer : this.rows.body;
      return dataset[rowIndex].data[colIndex];
    }

    _bodyRowSupplier (rowStart, rowEnd) {
      this.rows.body = [];
      let dataset = this.masterset.records;

      if (_.isFinite(rowStart)) {
        dataset = this.masterset.records.slice(rowStart, rowEnd);
      }
      Grid.rowspanCalculator(dataset, this.masterset.granularity.grains.values(this.package.viewGrainFilter));

      dataset.forEach((row, rowIndex) => {
        const rowData = {
          data: [],
          headers: []
        };
        const headerGrains = this.masterset.granularity.grains.values(this.package.viewGrainFilter);
        let subtotalHeader;
        headerGrains.forEach((grain) => {
          if (!_.isNil(row.rowspan) && row.rowspan[grain.id] > 0) {
            const headerCell = HeaderCell.create(
              row.granularity[grain.id],
              row.granularity[grain.id],
              'granularity',
              'text-strong',
              ClassDecorator.width(grain)
            ).setRowspan(row.rowspan[grain.id]);

            // If the row has a grain with the subtotal value, then it should be ensured that only a single subtotal value
            // is displayed for a given row. To do this, all grains with the subtotal value should be removed except the first,
            // and their colspan should be given to the first so that users only see a single subtotal value.
            // | someFc | Subtotal | Subtotal | Subtotal | 123 ... |  ->  | someFc | Subtotal                       | 123 ... |
            if (row.granularity[grain.id] === Enums.AggregateType.SUBTOTAL) {
              if (!_.isNil(subtotalHeader)) {
                subtotalHeader.incrementColspan();
                return;
              }
              subtotalHeader = headerCell.addClass('aggregate-cell');
            }
            rowData.headers.push(headerCell);
          }
        });

        // Backward Compatibility: 'row' can be either plain object or DataRow model
        const rowCols = row.cells || row.values;
        rowCols.forEach((col, colIndex) => {
          // Backward Compatibility: 'col' can be either plain object or DataCell model
          if (!_.isPlainObject(col) && !(col instanceof DataCell)) {
            col = _.isNil(col) ? { value: null } : { dataType: row.dataType, value: col };
          }
          const previousRowValue = rowIndex - 1 < 0 ? null : dataset[rowIndex - 1].values[colIndex];
          const cell = Cell.create(
            DataPackage.rowIsEditable(row) ? col.value : Display.data(col.value, row.metric.dataType, row.metric.decimalPlaces, this.masterset.editType, Configuration.planDisplayOverrides[_.get(this.package.plan, 'type')]),
            col.value,
            'text-right',
            ClassDecorator.aggregate(row.granularity),
            ClassDecorator.dataset(col.dataType, col.value),
            ClassDecorator.noPadding(col.dataType),
            // TODO: This behavior is specific to preview cells (Plan Editor) and do not need to be set for anything else.
            // Preview style is applied only if dataType is 'Preview' and previousRowValue (Edits value) is present
            ClassDecorator.preview(col.dataType, previousRowValue),
            ClassDecorator.verticalDividers(colIndex, this.package.historicalDividerIndex),
            ClassDecorator.width()
          );
          // TODO: These behaviors are specific to preview cells (Plan Editor) and do not need to be set for anything else.
          if (DataPackage.rowIsEditable(row)) {
            cell.setProperties({ lastValidValue: col.value });
            cell.setProperties({ originalValue: col.value });
          }
          // TODO: These behaviors are specific to preview cells (Plan Editor) and do not need to be set for anything else.
          if (DataPackage.rowIsEditPreview(row)) {
            cell.setProperties(
              {
                element: true,
                types: {
                  data: row.metric.dataType,
                  decimalPlaces: row.metric.decimalPlaces,
                  edit: this.masterset.editType
                }
              }
            );
          }
          // TODO: These behaviors are specific to editable inputs (Plan Editor) and do not need to be set for anything else.
          if (DataPackage.rowIsEditable(row)) {
            cell.setProperties(
              {
                input: {
                  bindings: {
                    'change keyup': (element, cell) => () => {
                      const rawValue = element.val(),
                            numericalValue = _.toNumber(rawValue);

                      if (rawValue === EMPTY_STRING) {
                        cell.set();
                        element.attr('value', EMPTY_STRING);
                        return;
                      }

                      if (!_.isFinite(numericalValue) || _.endsWith(rawValue, DECIMAL_POINT)) {
                        cell.set(NaN);
                        return;
                      }
                      cell.set(numericalValue);
                      element.attr('value', numericalValue);
                    }
                  },
                  pattern: INPUT_DECIMAL_REGEX_PATTERN
                },
                isEditable: true,
                set: (value) => {
                  const currentValue = dataset[rowIndex].values[colIndex],
                        editCell = this.rows.body[rowIndex].data[colIndex],
                        previewRowIndex = this._getRelatedPreviewRowIndex(rowIndex),
                        prefinalRowIndex = this._getRelatedPrefinalRowIndex(dataset, rowIndex),
                        previewDataset = this.masterset.plan.isFcWeeklyPlan() ? this.masterset.totals : dataset,
                        cellsToUpdate = [];

                  // Set the value in the DataPackage, then determine if the view needs to be updated
                  dataset[rowIndex].values[colIndex] = value;
                  if (currentValue === value || _.isNaN(value)) {
                    // Do nothing as the desired value is unchanged or invalid
                    return;
                  }

                  // Undo the last edit so it does not affect the current edit
                  previewDataset[previewRowIndex].values[colIndex] += editCell.lastValidValue * -1;
                  // Nil value requires special handling as it involves reseting the preview row and using null as the
                  // sentinel for the edit row value.
                  if (_.isNil(value)) {
                    dataset[rowIndex].values[colIndex] = null;
                    editCell.lastValidValue = 0;
                    // Non FcWeekly plans should have their preview rows reset to the prefinal value,
                    // FcWeekly plans are reset by subtracting the last valid value from the prefinal row
                    if (!this.masterset.plan.isFcWeeklyPlan()) {
                      previewDataset[previewRowIndex].values[colIndex] = dataset[prefinalRowIndex].values[colIndex];
                    }
                  } else {
                    let newPreview = value;
                    editCell.lastValidValue = value;
                    if (DataPackage.isDeltaEdit(this.masterset)) {
                      newPreview += previewDataset[previewRowIndex].values[colIndex];
                    }
                    previewDataset[previewRowIndex].values[colIndex] = newPreview;
                  }

                  // Always update the preview cell
                  cellsToUpdate.push({
                    cell: this._getRelatedPreviewCell(this.masterset.plan, previewRowIndex, colIndex),
                    value: previewDataset[previewRowIndex].values[colIndex]
                  });

                  // FcWeekly plans have an additional cell to update that is the difference of the preview and prefinal rows.
                  if (this.masterset.plan.isFcWeeklyPlan()) {
                    this.masterset.totals[FC_WEEKLY_PLAN_DIFFERENCE_INDEX].values[colIndex] =
                      this.masterset.totals[FC_WEEKLY_PLAN_PREVIEW_INDEX].values[colIndex] - this.masterset.totals[FC_WEEKLY_PLAN_PREFINAL_INDEX].values[colIndex];

                    cellsToUpdate.push({
                      cell: this.rows.footer[FC_WEEKLY_PLAN_DIFFERENCE_INDEX].data[colIndex],
                      value: this.masterset.totals[FC_WEEKLY_PLAN_DIFFERENCE_INDEX].values[colIndex]
                    });
                  }

                  _.forEach(cellsToUpdate, (updateObj) => {
                    if (_.isObject(updateObj.cell.element)) {
                      updateObj.cell.element.attr('value', updateObj.value);
                      updateObj.cell.element.html(Display.data(updateObj.value, updateObj.cell.types.data, updateObj.cell.types.decimalPlaces, updateObj.cell.types.edit, Configuration.planDisplayOverrides[_.get(this.package.plan, 'type')]));
                    }
                  });
                }
              }
            );
          }
          rowData.data.push(cell);
        });
        this.rows.body.push(rowData);
      });
      return this.rows.body;
    }

    _footerRowSupplier () {
      this.rows.footer = [];
      Grid.rowspanCalculator(this.masterset.totals, this.masterset.granularity.totalsGrains.values());

      this.masterset.totals.forEach((row) => {
        const rowData = {
          data: [],
          headers: []
        };

        this.masterset.granularity.totalsGrains.values().forEach((grain) => {
          if (!_.isUndefined(row.rowspan)) {
            rowData.headers.push(
              HeaderCell
                .create(
                  Name.ofMetric(row.granularity[grain.id]),
                  Name.ofMetric(row.granularity[grain.id]),
                  'aggregate-cell'
                )
                .setColspan(this.masterset.granularity.grains.values(this.package.viewGrainFilter).length)
                .setRowspan(row.rowspan[grain.id])
            );
          }
        });

        // Backward Compatibility: 'row' can be either plain object or DataRow model
        const rowCols = row.cells || row.values;
        rowCols.forEach((col, colIndex) => {
          // Backward Compatibility: 'col' can be either plain object or DataCell model
          if (!_.isPlainObject(col) && !(col instanceof DataCell)) {
            col = _.isNil(col) ? { value: null } : { dataType: row.dataType, value: col };
          }
          const cell = Cell.create(
            Display.data(col.value, row.metric.dataType, row.metric.decimalPlaces, this.masterset.editType),
            col.value,
            'text-right',
            ClassDecorator.aggregate(row.granularity),
            ClassDecorator.dataset(col.dataType, col.value),
            ClassDecorator.verticalDividers(colIndex, this.package.historicalDividerIndex)
          );

          if (DataPackage.rowIsEditDifference(row) || DataPackage.rowIsEditPreview(row)) {
            cell.setProperties(
              {
                element: true,
                types: {
                  data: row.metric.dataType,
                  edit: this.masterset.editType
                }
              }
            );
          }
          rowData.data.push(cell);
        });
        this.rows.footer.push(rowData);
      });
      return this.rows.footer;
    }

    _processRowsForChart (rows, headerGrains, isBody) {
      rows.forEach((row) => {
        const rowHeaders = [];
        headerGrains.forEach((grain, index) => {
          const header = isBody ? row.granularity[grain.id] : Name.ofMetric(row.granularity[grain.id]);
          rowHeaders.push(header);
          this.chartData.inputOptions[this.chartData.inputLabels[index]].push(header);
        });
        const rowData = _.map(row.cells, 'value');
        // Combines row headers to create an unique key like 'Hardlines / Sortable / ABE2'
        const headerKey = rowHeaders.join(' / ');
        if (!_.has(this.chartData.data, headerKey)) {
          this.chartData.data[headerKey] = [];
        }
        this.chartData.data[headerKey].push({
          key: CHART_DATASET.KEY[row.dataType],
          values: _.map(rowData, (value, index) => ({ x: index, y: value }))
        });
      });
    }

    _constructChartData () {
      if (!this.isChartingAvailable || this.areChartsDisabled()) {
        return;
      }
      // chartData must be cleared {} every time. Creating it once and updating it for every request will not trigger $onChanges() in Grid component.
      this.chartData = {};
      // inputSelections is an array of chart inputs received via this.package, either from ShareableUrls/Views or a simple callback circulation.
      this.chartData.inputSelections = _.get(this.package, 'chartInputs');
      const bodyHeaderGrains = this.masterset.granularity.grains.values(this.package.viewGrainFilter);
      // inputLabels is an array of granularity names like 'Product Line Group', 'Sort Type', etc.
      this.chartData.inputLabels = _.map(bodyHeaderGrains, 'name');
      // inputOptions is a map of input labels and grain values like 'Advantage', 'Hardlines', 'fullcase', 'sortable', etc.
      this.chartData.inputOptions = {};
      this.chartData.inputLabels.forEach((label) => this.chartData.inputOptions[label] = []);
      // data is a map of concatenated grain values and chart data to be plotted.
      this.chartData.data = {};
      this._processRowsForChart(this.masterset.records, bodyHeaderGrains, true);
      this._processRowsForChart(this.masterset.totals, this.masterset.granularity.totalsGrains.values());
      this.chartData.inputLabels.forEach((label) => {
        // Keep unique grain values to be listed in a selection dropdown.
        this.chartData.inputOptions[label] = _.uniq(this.chartData.inputOptions[label]);
      });
      const dates = _.map(this.package.dates, (date) => ({
        date: date,
        dateCompact: Display.date(date, 'MM-DD'),
        week: Display.date(date, Enums.DateFormat.WeekNumber),
        weekCompact: Display.date(date, Enums.DateFormat.WkNumber)
      }));
      const isTimeUnitWeek = DataPackage.isTimeUnitWeek(this.package);
      // Chart configuration options
      this.chartData.options = {
        chart: {
          interactiveLayer: {
            tooltip: {
              headerFormatter: (d) => isTimeUnitWeek ? dates[d].week : dates[d].date
            }
          },
          reduceXTicks: !isTimeUnitWeek,
          xAxis: {
            axisLabel: isTimeUnitWeek ? 'TIME (weeks)' : 'TIME (days)',
            tickFormat: (d) => isTimeUnitWeek ? dates[d].weekCompact : dates[d].dateCompact
          },
          yAxis: {
            axisLabel: 'UNITS'
          }
        }
      };
    }

    areChartsDisabled () {
      return !DataPackage.areModesUnit(this.package);
    }

    isMutableGrid () {
      return DataPackage.isMutable(this.package);
    }

    $onInit () {
      // Create the header before the thenable resolves so the title can be displayed
      this.gridData.header = Grid.header(
        this.package.title,
        () => Grid.defaultHeaderRowSupplier(this.package),
        { stickyHeaderColumns: _.get(this.package, 'enabled.freezeHeaderColumns', false) }
      );

      this.package.ready()
        .then((data) => {
          this.masterset = Object.assign(this.package, data);
          this.gridData.body = Grid.body(this.masterset.records.length, this._bodyRowSupplier.bind(this));
          this.gridData.footer = Grid.footer(this.masterset.totals.length, this._footerRowSupplier.bind(this));
        })
        .catch(() => {
          // On error, provide an empty body and footer to the grid service so it displays the no data available messaging
          this.gridData.body = Grid.body();
          this.gridData.footer = Grid.footer();
        })
        .finally(() => {
          this.viewMode = _.get(this.package, 'showViewAs');
          this._constructChartData();
        });
    }
  }

  angular.module('application.components')
    .component('planGrid', {
      bindings: {
        /*
         * @isChartingAvailable (Optional) boolean: If true, the chart action icon buttons are displayed/visible. If false, they are hidden. (defaults to false)
         */
        isChartingAvailable: '<',
        /*
         * @onChartInputSelectionChange Function: a callback for change in chart input selections
         */
        onChartInputSelectionChange: '&',
        /*
         * @package data package that holds all the data (header, body, footer) for a grid/table.
         */
        package: '<'
      },
      controller: PlanGridController,
      templateUrl: 'templates/components/plan-grid.component.html'
    });
})();
